<script setup lang="ts">
import { ref } from 'vue'

const isOpened = ref(true)
const removeWrapper = () =>{
  isOpened.value = false
  document.getElementById('wrapper')?.classList.remove('wrap')
}
</script>

<template>
  <div>
    <div id="wrapper" :class="['content', 'w-full', 'wrap']">
      <slot name="content"></slot>
    </div>
    <slot name="more">
      <span
        class="more underline font-bold"
        @click="removeWrapper"
        v-if="isOpened"
      >
        Read more
      </span>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  :deep(.html-formatter p) {
    &:first-of-type {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }

    &:not(:first-of-type) {
      display: none;
    }
  }
}

.more {
  color: var(--vt-c-link);
  display: flex;
  cursor: pointer;
}

@media screen and (min-width: 560px) {
  .wrap {
    :deep(.html-formatter p) {
      &:first-of-type {
        display: flex;
        overflow: none;
      }

      &:not(:first-of-type) {
        display: flex;
      }
    }
  }

  .more {
    display: none;
  }
}
</style>

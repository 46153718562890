<script setup lang="ts">
import type { PropType } from 'vue'
import { ApplicationMode } from '@/enums/ApplicationMode'
import { IHeroCard } from '@/interface/IBasicHeaderHero'
import HeroCard from '@/components/HeroCard.vue'
import { IImage } from '@/interface/IImage'
import { Routes } from '@/enums/Routes'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  describe: {
    type: String,
    required: true,
  },
  background: {
    type: [Object, String] as PropType<IImage | string>,
    required: true,
  },
  hideText: {
    type: Boolean,
    required: false,
    default: false,
  },
  mode: {
    type: String as PropType<ApplicationMode>,
    required: false,
  },
  heroCard: {
    type: Object as PropType<IHeroCard | null>,
    required: false,
  },
})

const procedure = computed(() => {
  if (!props.heroCard && !props.mode) return ApplicationMode.Short
  if (props.mode) return props.mode
  return ApplicationMode.Header
})

const setHeightHeaderSection = (applicationMode: ApplicationMode, hero: boolean) => {
  if (applicationMode === ApplicationMode.Short && hero)
    return 'short-hero-card'
  if (applicationMode === ApplicationMode.Short)
    return 'short'
  return 'min-h-screen'
}

const AsyncAppComponent = defineAsyncComponent(() =>
  import('@/components/ApplicationContainer.vue')
);
</script>

<template>
  <AsyncAppComponent
    class="flex flex-col justify-center"
    :mode="procedure"
    :background="background"
  >
    <div
      :class="[
        'header__container',
        setHeightHeaderSection(procedure, !!heroCard),
      ]"
    >
      <h1 class="header__container__title" v-text="title" />
      <h2 class="header__container__copy" v-text="describe" />

      <HeroCard
        v-if="heroCard"
        :photo="heroCard.image"
        :info-title="heroCard.title"
        :info-describe="heroCard.copy"
        :info-btn-text="heroCard.buttonText"
        :info-btn-link="`${Routes.DEV_MARKET}/#market-section`"
      />
      <slot v-else />
    </div>
  </AsyncAppComponent>
</template>

<style scoped lang="scss">
.header__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 4rem;
  padding-top: 6rem;

  &.short {
    justify-content: flex-end;
    height: clamp(650px, 56vh, 900px);
    margin-bottom: -4rem;
    padding-bottom: 6rem;
  }

  &.short-hero-card {
    justify-content: flex-end;
    margin-bottom: -4rem;
    padding-bottom: 6rem;
  }

  &__title {
    color: var(--vt-c-white);
    font-weight: bold;
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 1rem;
    max-width: 90%;
  }

  &__copy {
    color: var(--vt-c-white);
    font-weight: bold;
    font-size: 29px;
    line-height: 34px;
    max-width: 90%;
    margin-bottom: 1.5rem;
    max-height: 344px;
    overflow: hidden;
  }
}

@media screen and (min-width: 750px) {
  .header__container {
    &__title {
      font-size: 90px;
      line-height: 90px;
    }

    &__copy {
      font-size: 36px;
      line-height: 40px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .header__container {
    padding-top: 156px;

    &__title {
      max-width: 70%;
    }

    &__copy {
      max-width: 70%;
    }
  }
}
</style>

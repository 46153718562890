<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  active: {
    type: Boolean,
    required: false,
    default: true,
  },
  btnClass: {
    type: [String, Array],
    required: false,
    default: '',
  },
  backgroundColor: {
    type: String,
    required: false,
    default: '',
  },
})

const cssClass = computed(() => {
  let defaultClass = ['text-white', 'py-2', 'px-12', 'rounded-md']

  if (Array.isArray(props.btnClass)) {
    return [...defaultClass, ...props.btnClass]
  }
  return defaultClass.concat(props.btnClass.split(' '))
})
</script>

<template>
  <button
    :disabled="!active"
    :class="[ { mask: !active }, cssClass, 'relative' ]"
    :style="{
      backgroundColor: `${
        backgroundColor ? backgroundColor : 'var(--vt-c-btn-blue)'
      }`,
    }"
  >
    <slot />
  </button>
</template>

<style scoped>
.mask::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(51, 47, 47, 0.3);
  border-radius: 8px;
}
</style>
